import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuthCallPromise } from 'use-eazy-auth'
import { CalendarDocument, Doc, DocDetail, PaginatedDJResponse } from '../types'
import { makeFormDataEncoder } from './utils'

export function useDocs(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<Doc>>(
    ['docs', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/documents/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useCalendarDocs(params: Record<string, any> = {}) {
  return useQuery<PaginatedDJResponse<CalendarDocument>>(
    ['calendar-docs', params],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(
              `/api/admin/calendar-documents/?${new URLSearchParams(params).toString()}`,
              {
                signal,
                headers: { Authorization: `Bearer ${token}` },
              }
            )
            .then((r) => r.data)
    ),
    {
      keepPreviousData: true,
    }
  )
}

export function useDoc(id: number) {
  return useQuery<DocDetail>(
    ['doc', id],
    useAuthCallPromise(
      (token: string) =>
        ({ signal }) =>
          axios
            .get(`/api/admin/documents/${id}/`, {
              signal,
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((r) => r.data)
    )
  )
}

export function useDeleteDoc() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/documents/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['docs'])
        client.invalidateQueries(['doc'])
        client.invalidateQueries(['theme'])
        client.invalidateQueries(['themes'])
      },
    }
  )
}

export interface ImportedDocData {
  imported_documents: number
}

export function useImportDocs() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise((token: string) => (file: File) => {
      const fd = new FormData()
      fd.append('file', file)
      return axios
        .put('/api/admin/documents/import/', fd, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((r) => r.data as ImportedDocData)
    }),
    {
      onSuccess() {
        client.invalidateQueries(['docs'])
        client.invalidateQueries(['doc'])
        client.invalidateQueries(['theme'])
        client.invalidateQueries(['themes'])
      },
    }
  )
}

const makeDocFormData = makeFormDataEncoder({
  fileFields: ['file_content'],
  excludeFields: [
    'text_tokens',
    'image_medium',
    'image_preview',
    'image_thumb',
    'updated_at',
    'content_metadata',
  ],
})

export function useCreateDoc() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (doc: DocDetail) =>
        axios
          .post(`/api/admin/documents/`, makeDocFormData(doc), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as DocDetail)
    ),
    {
      onSuccess(savedDoc) {
        client.invalidateQueries(['docs'])
        client.setQueryData(['doc', savedDoc.id], savedDoc)
      },
    }
  )
}

export function useUpdateDoc() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (doc: DocDetail) =>
        axios
          .put(`/api/admin/documents/${doc.id}/`, makeDocFormData(doc), {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as DocDetail)
    ),
    {
      onSuccess(savedDoc) {
        client.invalidateQueries(['docs'])
        client.setQueryData(['doc', savedDoc.id], savedDoc)
        client.invalidateQueries(['theme'])
      },
    }
  )
}
