import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useAuthCallPromise } from 'use-eazy-auth'
import { BaseCalendarDocument, CalendarDocument } from '../types'

export function useCreateCalendarDoc() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (calendar: Omit<BaseCalendarDocument, 'document_data'>) =>
        axios
          .post(`/api/admin/calendar-documents/`, calendar, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as CalendarDocument)
    ),
    {
      onSuccess(savedCalendar) {
        client.invalidateQueries(['calendar-docs'])
        client.setQueryData(['calendar-docs', savedCalendar.id], savedCalendar)
      },
    }
  )
}

export function useUpdateCalendarDoc() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (calendar: Omit<CalendarDocument, 'document_data'>) =>
        axios
          .put(`/api/admin/calendar-documents/${calendar.id}/`, calendar, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((r) => r.data as CalendarDocument)
    ),
    {
      onSuccess(savedCalendar) {
        client.invalidateQueries(['calendar-docs'])
        client.setQueryData(['calendar-doc', savedCalendar.id], savedCalendar)
      },
    }
  )
}

export function useDeleteCalendarDoc() {
  const client = useQueryClient()
  return useMutation(
    useAuthCallPromise(
      (token: string) => (id: number) =>
        axios.delete(`/api/admin/calendar-documents/${id}/`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    ),
    {
      onSuccess() {
        client.invalidateQueries(['calendar-docs'])
        client.invalidateQueries(['calendar-doc'])
      },
    }
  )
}
